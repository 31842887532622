import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import BrandButton from "@components/common/BrandButton"
import LocationMap from "@components/common/LocationMap"

import language from "@language"

import "./style.scss"

const ProfileLocationSection = props => {
  return (
    <div className="profile-location-section">
      <div className="page-wrap">
        <div className="row">
          <div className="profile-location-section__left col-md-6">
            {props.index === 0 && (
              <HeadingText
                className="profile-location-section__work-title"
                size="md"
                color="dark"
                text={props.title}
              />
            )}
            <HeadingText
              className="profile-location-section__top-title"
              size="lg"
              color="primary-dark"
              text={(props.location.acf.type || {}).description}
            />
            <HeadingText
              className="profile-location-section__bottom-title"
              size="lg"
              color="primary"
              text={props.location.title}
            />
            <div className="profile-location-section__address">
              <BodyText
                size="md"
                color="dark-gray"
                text={props.location.acf.streetAddress}
              />
              <BodyText
                size="md"
                color="dark-gray"
                text={`${props.location.acf.city}, ${props.location.acf.state} ${props.location.acf.zipCode}`}
              />
            </div>
            <div className="profile-location-section__contact">
              <BodyText
                size="md"
                color="dark-gray"
                text={props.location.acf.email}
              />
              <BodyText
                size="md"
                color="dark-gray"
                text={`${props.location.acf.telephone}`}
              />
            </div>
            <BrandButton
              className="profile-location-section__cta"
              color="secondary"
              text={language[props.language].learn_more}
              href={props.location.uri}
            />
          </div>
          <div className="profile-location-section__right col-md-6">
            <LocationMap
              className="profile-location-section__map"
              center={{
                lat: props.location.acf ? props.location.acf.map.latitude : 0,
                lng: props.location.acf ? props.location.acf.map.longitude : 0,
              }}
              zoom={15}
              handleGoogleMapsLoaded={() => { }}
              locations={
                !!Object.keys(props.location).length ? [props.location] : []
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ProfileLocationSection.propTypes = {
  index: PropTypes.number,
  isFinal: PropTypes.bool,
  locationsIndexUri: PropTypes.string,
  location: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    acf: PropTypes.shape({
      map: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      email: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      streetAddress: PropTypes.string,
      stateAbbreviation: PropTypes.string,
      telephone: PropTypes.string,
      zipCode: PropTypes.string,
    }),
  }),
  language: PropTypes.string,
  title: PropTypes.string,
}
ProfileLocationSection.defaultProps = {
  index: 0,
  location: {},
  language: "es",
}

export default ProfileLocationSection
