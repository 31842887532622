import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import Markdown from "markdown-to-jsx"

import language from "@language"
import { preFilterMarkdown } from "@utilities/markdownUtils"

import BrandButton from "@components/common/BrandButton"
import CopyLinkButton from "@components/common/CopyLinkButton"
import ArticleMarkdownContent from "@components/common/ArticleMarkdownContent"

import CareerProfileCard from "@templates/careers/components/CareerProfileCard"
import "./style.scss"

const CareerProfileSection = props => {
  return (
    <div className="career-profile-section">
      <div className="page-wrap">
        <div className="row">
          <div className="career-profile-section__left col-md-6 offset-md-1">
            <HeadingText
              className="career-profile-section__name"
              size="xl"
              color="primary"
              text={props.career.acf.title}
            />
            <hr className="career-profile-section__short-divider" />
            <div className="career-profile-section__departments-locations">
              {props.career.acf.employeeDepartment && (
                <BodyText
                  size="lg"
                  color="dark-gray"
                  text={props.career.acf.employeeDepartment.name}
                />
              )}
              {props.career.acf.location && (
                <BodyText
                  size="lg"
                  color="dark-gray"
                  text={props.career.acf.location.title}
                />
              )}
            </div>
            <CareerProfileCard
              className="mobile-md"
              language={props.language}
              career={props.career}
            />
            <CopyLinkButton className="mlr-auto mt-30px mobile-md" />

            {props.career.acf.description && (
              <div className="career-profile-section__about">
                <HeadingText
                  className="career-profile-section__about-title"
                  size="md"
                  color="dark"
                  text={language[props.language].about_the_position}
                />
                <ArticleMarkdownContent
                  content={props.career.acf.description}
                />
              </div>
            )}
            {props.career.acf.qualifications && (
              <div className="career-profile-section__module">
                <HeadingText
                  className="career-profile-section__module-title"
                  size="md"
                  color="dark"
                  text={language[props.language].qualifications}
                />
                <ul>
                  {preFilterMarkdown(props.career.acf.qualifications)
                    .replace(/\r\n<br \/>/gi, "") // remove double <br>
                    .split("<br />")
                    .map(qualification => {
                      return (
                        <BodyText element="li" size="md2" color="dark-gray">
                          {qualification}
                        </BodyText>
                      )
                    })}
                </ul>
              </div>
            )}
            {props.career.acf.applicationInformation && (
              <div className="career-profile-section__module">
                <HeadingText
                  className="career-profile-section__module-title"
                  size="md"
                  color="dark"
                  text={language[props.language].application}
                />
                <Markdown
                  color="dark-gray"
                  options={{
                    overrides: {
                      p: {
                        component: BodyText,
                        props: {
                          color: "dark-gray",
                          size: "md",
                        },
                      },
                    },
                  }}
                >
                  {preFilterMarkdown(props.career.acf.applicationInformation)}
                </Markdown>
                <BrandButton
                  color="primary"
                  className="mt-30px mlr-auto ml-md-0"
                  href={props.career.acf.applicationUrl}
                  text={language[props.language].apply_here}
                />
              </div>
            )}
          </div>
          <div className="career-profile-section__right col-md-4 offset-md-1">
            <CareerProfileCard
              className="desktop-md"
              language={props.language}
              career={props.career}
            />
            <CopyLinkButton className="mlr-auto mt-30px desktop-flex-md" />
          </div>
        </div>
      </div>
    </div>
  )
}

CareerProfileSection.propTypes = {
  career: PropTypes.shape({
    acf: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
      location: PropTypes.shape({
        title: PropTypes.string,
      }),
      employeeDepartment: PropTypes.shape({
        name: PropTypes.string,
      }),
      // compensation: PropTypes.string,
      postedDate: PropTypes.string,
    }),
  }),
  language: PropTypes.string,
}
CareerProfileSection.defaultProps = {
  language: "es",
  career: {
    acf: {},
  },
}

export default CareerProfileSection
