import React from "react"
import PropTypes from "prop-types"
import BodyText from "@components/common/BodyText"

import language from "@language"
import { shortDateFormat } from "@utilities/copyUtils"

import classnames from "classnames"

import "./style.scss"

const CareerProfileCard = props => {
  return (
    <div className={classnames("career-profile-card", props.className)}>
      <div className="career-profile-card__content">
        {props.career.acf.title && (
          <div className="career-profile-card__content-section">
            <BodyText
              color="primary"
              size="sm"
              text={language[props.language].title}
            />
            <BodyText
              color="dark-gray"
              size="sm"
              text={props.career.acf.title}
            />
          </div>
        )}
        {props.career.acf.location && (
          <div className="career-profile-card__content-section">
            <BodyText
              color="primary"
              size="sm"
              text={language[props.language].location}
            />
            <BodyText
              color="dark-gray"
              size="sm"
              text={props.career.acf.location.title}
            />
          </div>
        )}
        {props.career.acf.employeeDepartment && (
          <div className="career-profile-card__content-section">
            <BodyText
              color="primary"
              size="sm"
              text={language[props.language].employeeDepartment}
            />
            <BodyText
              color="dark-gray"
              size="sm"
              text={props.career.acf.employeeDepartment.name}
            />
          </div>
        )}
        {/* {props.career.acf.compensation && (
          <div className="career-profile-card__content-section">
            <BodyText
              color="primary"
              size="sm"
              text={language[props.language].compensation}
            />
            <BodyText
              color="dark-gray"
              size="sm"
              text={props.career.acf.compensation}
            />
          </div>
        )} */}
        {props.career.acf.postedDate && (
          <div className="career-profile-card__content-section">
            <BodyText
              color="primary"
              size="sm"
              text={language[props.language].date}
            />
            <BodyText
              color="dark-gray"
              className="text-capitalize"
              size="sm"
              text={shortDateFormat(
                props.language,
                props.career.acf.postedDate
              )}
            />
          </div>
        )}
      </div>
    </div>
  )
}

CareerProfileCard.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  career: PropTypes.shape({
    acf: PropTypes.shape({
      title: PropTypes.string,
      location: PropTypes.shape({
        title: PropTypes.string,
      }),
      employeeDepartment: PropTypes.shape({
        name: PropTypes.string,
      }),
      // compensation: PropTypes.string,
      postedDate: PropTypes.string,
    }),
  }),
}
CareerProfileCard.defaultProps = {
  career: {
    acf: {},
  },
  language: "es",
}

export default CareerProfileCard
