import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingText from "@components/common/HeadingText"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import LargeArrowButton from "@components/common/LargeArrowButton"

import CareerProfileSection from "@templates/careers/components/CareerProfileSection"
import ProfileLocationSection from "@components/ProfileLocationSection"
import language from "@language"

import "./show.scss"

const CareerShow = props => {
  const page =
    !!props.preview && !!props.preview.career
      ? props.preview.career.preview.node
      : props.data.allWpCareer.edges[0].node
  const indexPage =
    props.pageContext.pages.find(
      p => p.acfPageMeta.pageTemplate === "careers"
    ) || {}

  const locationsIndex =
    props.pageContext.pages.find(
      p => p.acfPageMeta.pageTemplate === "locations"
    ) || {}

  return (
    <Layout>
      <div className="career-show page-fade-in">
        <SEO title={page.title} />
        <SimpleBreadcrumbHero
          pageTitle={page.title}
          size="sm"
          breadcrumbLink2={{
            url: indexPage.uri,
            title: indexPage.acfPageMeta.metaTitle,
          }}
        />
        <CareerProfileSection career={page} language={props.language} />
        {page.acf.location && (
          <ProfileLocationSection
            index={0}
            title={language[props.language].location}
            locationsIndexUri={locationsIndex.uri}
            isFinal={true}
            location={page.acf.location}
            language={props.language}
          />
        )}
        )
      </div>
      <div className="page-wrap career-show__cta-section pt-80px pb-80px">
        <LargeArrowButton direction="left" uri={indexPage.uri}>
          <HeadingText
            className="career-show__cta-text"
            size="xl"
            color="primary"
            text={language[props.language].back_to_jobs}
          />
        </LargeArrowButton>
      </div>
    </Layout>
  )
}

CareerShow.propTypes = {
  data: PropTypes.object,
  language: PropTypes.string,
  pageContext: PropTypes.shape({
    pages: PropTypes.array,
  }),
}
CareerShow.defaultProps = {
  data: {
    allWpCareer: {
      edges: [],
    },
  },
  language: "es",
  pageContext: {
    pages: [],
  },
}

export const query = graphql`
  query($slug: String) {
    allWpCareer(filter: { slug: { eq: $slug, ne: null } }) {
      edges {
        node {
          title
          uri
          acf {
            applicationInformation
            applicationUrl
            description
            location {
              ... on WpLocation {
                id
                uri
                title
                acf {
                  email
                  telephone
                  map {
                    placeId
                    longitude
                    latitude
                  }
                  type {
                    id
                    name
                    description
                  }
                  city
                  stateAbbreviation
                  state
                  streetAddress
                  email
                  telephone
                  zipCode
                }
              }
            }
            postedDate
            qualifications
            title
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewCareerShowQuery($id: ID!) {
    career(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          title
          uri
          acf {
            applicationInformation
            applicationUrl
            employeeDepartment {
              id
              name
             }
            description
            location {
              ... on Location {
                id
                uri
                title
                acf {
                  email
                  telephone
                  map {
                    placeId
                    longitude
                    latitude
                  }
                  type {
                    id
                    name
                    description
                  }
                  city
                  stateAbbreviation
                  state
                  streetAddress
                  email
                  telephone
                  zipCode
                }
              }
            }
            postedDate
            qualifications
            title
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(CareerShow)
